import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

const NotFoundPage = () => (
  <>
    <SEO title="404: Not found" />
    <Layout sections={[<h1>NOT FOUND</h1>]} />
  </>
);

export default NotFoundPage;
